<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col cols="8">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Oda Kayıt Türleri</h4>
                </b-col>
              </div>
            </b-row>
            <table class="table table-bordered mt-1">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  İSİM
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  ENTERGRASYON KODU
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in this.$store.getters.odaYonetimTurReturn"
                  :key="index"
                  class="text-center"
              >
                <td>{{ item.adi }}</td>
                <td>{{ item.tip }}</td>
                <td>
                  <b-button
                      variant="bgInfo"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="odaGuncelle(item)"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      variant="bgDanger"
                      class="btn-icon rounded-circle"
                      size="sm"
                      @click="odaRemove(item.tipID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col cols="4">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row-btn" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">{{
                      isEdit == false ? 'Oda Türü Kaydı' : editName + ' Adlı Oda Türü Düzenleniyor'
                    }}</h4>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="bgInfo"
                      class="myButton mr-1 border-0 float-right"
                      to="/odaYonetimi"
                      :style="{backgroundColor:$store.getters.cardTitleButton}"
                  >
                    <feather-icon
                        icon="ChevronLeftIcon"
                        class="mr-50"
                    />
                    Oda Listesi
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <hr>
            <b-form-group
                label="Entegrasyon Kodu"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="entegrasyonKodu"

                  placeholder="Entegrasyon Kodunu Giriniz"
                  @keyup.enter="!isEdit ? odaSave() : odaUpdate()"
                  autocomplete="off"
              />
            </b-form-group>
            <b-form-group
                label="Oda Türü İsmi"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="departmanName"
                  placeholder="Oda Türü İsmi"
                  autocomplete="off"
                  @keyup.enter="!isEdit ? odaSave() : odaUpdate()"
              />
            </b-form-group>
            <b-row class="odaKayitRow">
              <b-button
                  v-show="isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mt-1 mr-1 border-0 text-white"
                  :style="{backgroundColor:$store.getters.bgDanger}"
                  @click="editClosed"
              >
                Vazgeç
              </b-button>
              <b-button
                  v-show="isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="odaUpdate"
              >
                Güncelle
              </b-button>
              <b-button
                  v-show="!isEdit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="bgInfo"
                  class="mt-1 mr-1 border-0"
                  :style="{backgroundColor:$store.getters.bgSuccess}"
                  @click="odaSave"
              >
                Kaydet
              </b-button>
            </b-row>
            <b-row :class="{ editOnPrgoress: isEdit }"/>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile, BSpinner,
} from 'bootstrap-vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    quillEditor,
    BFormFile,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: true,
      isEdit: false,
      editName: '',
      departmanName: '',
      entegrasyonKodu: '',
      updateID: '',
    }
  },
  computed: {},
  created() {
    this.$store.dispatch('odaYonetimTur')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('celarOdaYonetimTurSave')
  },
  methods: {
    odaSave() {
      if (this.departmanName) {
        if (this.entegrasyonKodu) {
          this.$store
              .dispatch('odaYonetimTurSave', {
                isim: this.departmanName,
                tip: this.entegrasyonKodu,
              })
              .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                (this.departmanName = ''),
                    (this.entegrasyonKodu = '')
                const mesaj = this.$store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  }, { position })
                }
              })
              .catch(err => {
                const mesaj = this.$store.getters.notificationSettings(err)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Entegrasyon Kodu Giriniz',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Oda Türünü Giriniz',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    odaUpdate() {
      if (this.departmanName) {
        if (this.entegrasyonKodu) {
          this.$store
              .dispatch('odaYonetimTurUpdate', {
                isim: this.departmanName,
                tip: this.entegrasyonKodu,
                id: this.updateID,
              })
              .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
                this.isEdit = false
                this.departmanName = ''
                this.entegrasyonKodu = ''
                const mesaj = this.$store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  }, { position })
                }
              })
              .catch(err => {
                const mesaj = this.$store.getters.notificationSettings(err)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: '!Hata',
              text: 'Entegrasyon Kodu Giriniz',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '!Hata',
            text: 'Oda Türünü Giriniz',
            icon: 'ThumbsDownIcon',
            variant: 'danger',
          },
        })
      }
    },
    odaGuncelle(item) {
      this.isEdit = true
      this.departmanName = item.adi
      this.editName = item.adi
      this.entegrasyonKodu = item.tip
      this.updateID = item.tipID
    },
    editClosed() {
      this.isEdit = false
      this.departmanName = ''
      this.updateID = ''
      this.entegrasyonKodu = ''
    },
    odaRemove(val) {
      this.$store
          .dispatch('odaYonetimTurRemove', { id: val })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
  },
}
</script>

<style scoped>
.myCol {
  padding-right: 0 !important;
}

@keyframes mymove {
  0% {
    background-color: pink;
  }

  25% {
    background-color: pink;
  }

  50% {
    background-color: #00ffd1;
  }

  75% {
    background-color: #00ffd1;
  }

  100% {
    background-color: #00ffd1;
    width: 480px;
  }
}

.editOnPrgoress {
  width: 0px;
  height: 4px;
  background: #38e54d;
  position: relative;
  animation: mymove 4000ms infinite;
  border-radius: 20px;
  transform: translateY(20px);
  /* animation-iteration-count: 1 !important; */
}

.odaKayitRow {
  justify-content: space-around !important;
}

.table td[data-v-199ebc45][data-v-199ebc45] {
  text-align: center;
}

.myColor {
  width: 30px;
  height: 30px;
  border-radius: 14px;
  margin: 0 auto !important;
}

[dir] .table th[data-v-199ebc45],
[dir] .table td[data-v-199ebc45] {
  padding: 0.1rem;
}

.row {
  justify-content: end;
}

[dir] .table th,
[dir] .table td {
  padding: 0.72rem;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
  padding: 0.6rem !important;
  border: none;
  text-align: center;
}

[dir] .table th[data-v-4ab16a2a],
[dir] .table td {
  padding: 0.2rem !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
  padding: 3px !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
